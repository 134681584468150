import React, { useState, useEffect } from "react";
import { Container, Row, Col, Tabs, Tab } from "react-bootstrap";
import { useStaticQuery, graphql } from "gatsby";
import Slider from "react-slick";
import PropertyCard from "../PropertyCard/PropertyCard"
import ReasonCard from "../OffPlanCard/ReasonCard"
import { FeaturedProperties } from "../../queries/common_use_query";
// import ScrollAnimation from 'react-animate-on-scroll';
import "./PropertyFeatures.scss";

import { UserObjectStoreProvider, useAuthState, getUserobjectData } from "@starberry/myaccount-website-utils";

const PropertyFeaturesComponent = (props) => {
  const data = useStaticQuery(graphql`
    query  {  
        glstrapi {
            featuredProperties {
                Listing_Ids
                Title
                Type
                Department
            }
            offplans(where: {publish:true}, sort: "createdAt:DESC", limit: 4)  {
              URL
              id
              location
              developer
              name
              imagetransforms
              Banner_Img {
                url
                url_sharp {
                  childImageSharp {
                    gatsbyImageData(
                      formats: WEBP
                      width: 500
                      quality: 80
                      layout: FIXED
                      transformOptions: { cropFocus: CENTER, fit: COVER }
                    )
                  }
                }
              }
            }
        }
    }
    `)
  var featured_properties = data.glstrapi.featuredProperties;
  var offplans = data.glstrapi.offplans
  var filtersSale = featured_properties && featured_properties.find(obj => obj.Department == "Residential" && obj.Type == "Sale")
  var filterRent = featured_properties && featured_properties.find(obj => obj.Department == "Residential" && obj.Type == "Rent")
  var comFilterSale = featured_properties && featured_properties.find(obj => obj.Department == "Commercial" && obj.Type == "Sale")
  var comFilterRent = featured_properties && featured_properties.find(obj => obj.Department == "Commercial" && obj.Type == "Rent")
  var saleids = filtersSale && filtersSale.Listing_Ids
  var rentids = filterRent && filterRent.Listing_Ids
  var comSaleids = comFilterSale && comFilterSale.Listing_Ids
  var comRentids = comFilterRent && comFilterRent.Listing_Ids
  var ids = saleids +','+ rentids +','+ comSaleids
 

  const { loading: prop_all_sale, error: prop_eall_sale, data: prop_all_data } = FeaturedProperties(ids.split(/[ ,]+/), ["for sale","to let"], ["residential","commercial"]);
  const   prop_data_sale   = prop_all_data ? prop_all_data.properties.filter(item => item.search_type == "sales" && item.department == "residential") : []
  const   prop_data_rent  = prop_all_data ? prop_all_data.properties.filter(item => item.search_type == "lettings" && item.department == "residential") : []
  const  prop_data_com_sale  = prop_all_data ? prop_all_data.properties.filter(item => item.search_type == "sales" && item.department == "commercial") : []
  // const { loading: prop_loading_com_rent, error: prop_error_com_rent, data: prop_data_com_rent } = FeaturedProperties(comRentids.split(/[ ,]+/), ["to let"], ["commercial"]);

  const [currentTab, setCurrenttab] = useState("sale")
  // console.log("prop_all_sale",prop_all_sale)
  const tabList = [
    { event: "sale", title: "property for sale", url: "/property-for-sale/", data: prop_data_sale },
    { event: "rent", title: "property for rent", url: "/property-for-rent/", data: prop_data_rent },
    { event: "com_sale", title: "commercial property for sale", url: "/commercial-property-for-sale/", data: prop_data_com_sale },
    // { event: "offPlan", title: "New Projects In", url: "/commercial-property-for-sale/", data: offplans },
    // { event: "com_rent", title: "commercial property for sale", url: "/commercial-property-for-rent/", data: prop_data_com_rent }
  ]

  var settings = {
    dots: true,
    arrows: false,
    infinite: false,
    autoplay: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    mobileFirst: true,
    responsive: [
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 2800,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
    ],
  };


  const handleSelect = (key) => {
    setCurrenttab(key)
  }
  return (
    <React.Fragment>
      {prop_all_data && prop_all_data.properties.length > 0?
        <section className="property-features home">
          <Container>
            <Row>
              <Col md={12}>
                <h3 className="heading featured-title">Featured Properties</h3>
                <Tabs defaultActiveKey={currentTab} onSelect={(key) => handleSelect(key)}>

                  {
                    tabList.map((tab, key) => {
                      return (
                        <Tab eventKey={tab.event} title={`${tab.title} in ${props.areaname ? props.areaname : "Dubai"}`} key={key}>
                          <Slider {...settings}>
                            {tab.data && tab.data.map((property, index) => {
                              let processedImages = JSON.stringify({});
                              if (property?.imagetransforms?.images_Transforms) {
                                processedImages = property.imagetransforms.images_Transforms;
                              }
                              var uriStr = tab.url;
                              return (
                                <PropertyCard key={index} property={property}
                                  price={property?.price && "AED " + property?.price?.toLocaleString()}
                                  PropertyPrice={property?.price && property?.price?.toLocaleString()}
                                  propertyid={property?.id}
                                  location={property?.address?.address}
                                  description={property.description}
                                  bedroomsCount={property.bedroom}
                                  bathroomsCount={property.bathroom}
                                  propertyImages={property.images}
                                  propertyDetailsLink={`${uriStr}${property.slug}-${property.id}/`}
                                  status={property.status}
                                  priceQualifier={property.price_qualifier}
                                  propertySearchType={property.search_type}
                                  sqft={property.size}
                                  card_type="similar_property"
                                  processedImages={processedImages}
                                  userObjects={props.userObjects}
                                />
                              )
                            })}
                          </Slider>

                        </Tab>
                      )
                    })
                  }
                  <Tab eventKey={'offPlan'} title={`Off Plan Properties in ${props.areaname ? props.areaname : "Dubai"}`} key={4}>
                    <div className="offplan">
                      <Slider {...settings}>
                        {offplans.slice(0, 4).map(item => (
                          <ReasonCard key={item.id} card={item} />
                        ))}
                      </Slider>
                    </div>


                  </Tab>
                </Tabs>
              </Col>
            </Row>
          </Container>
        </section>
        : ""}
    </React.Fragment>
  );
};

const PropertyFeatures = (props) => {
  const [userObjects, setUserObjects] = useState({});
  const { state: authState, services: authServices } = useAuthState();
  const isAuthenticated = authServices.isAuthenticated();

  useEffect(() => {
    if (isAuthenticated) {
      const getUserObjects = async () => {
        try {
          const userObjects = await getUserobjectData();
          setUserObjects(userObjects.data.data);
        } catch (e) {
          console.log("e:", e);
        }
      }
      getUserObjects()
    }

  }, []);

  return (
    <UserObjectStoreProvider>
      <PropertyFeaturesComponent {...props} userObjects={userObjects} />
    </UserObjectStoreProvider>
  )
}

export default PropertyFeatures;
